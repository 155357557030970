import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';

import dayjs from '../utils/dayjs';
import LinkIcon from '../assets/icons/external-link.svg';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import ObserverSection from '../components/ObserverSection';

const Calendar = () => {
  const data = useStaticQuery(graphql`
    query calendarQuery {
      allWpEvent {
        nodes {
          id
          eventSettings {
            date
            eventName
            eventNameEn
            fieldGroupName
            linkToEvent
            platformName
          }
          dateGmt
        }
      }
    }
  `);
  const events =
    data?.allWpEvent?.nodes?.sort((a, b) => {
      if (
        (!a?.eventSettings?.date && b?.eventSettings?.date) ||
        (!a?.eventSettings?.date &&
          !b?.eventSettings?.date &&
          dayjs(a?.dateGmt).isAfter(dayjs(b?.dateGmt))) ||
        (a?.eventSettings?.date &&
          b?.eventSettings?.date &&
          dayjs(a?.eventSettings?.date).isAfter(dayjs(b?.eventSettings?.date)))
      ) {
        return 1;
      }

      if (
        (a?.eventSettings?.date && !b?.eventSettings?.date) ||
        (!a?.eventSettings?.date &&
          !b?.eventSettings?.date &&
          dayjs(a?.dateGmt).isBefore(dayjs(b?.dateGmt))) ||
        (a?.eventSettings?.date &&
          b?.eventSettings?.date &&
          dayjs(a?.eventSettings?.date).isBefore(dayjs(b?.eventSettings?.date)))
      ) {
        return -1;
      }

      return 0;
    }) || [];

  const intl = useIntl();
  const { locale } = intl;
  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: 'calendar.title' })} />
      <div className="page-title">
        {intl.formatMessage({ id: 'calendar.title' })}
      </div>
      <div className="page-title-underline" />
      <div className="calendar-wrapper">
        <div className="calendar-timeline" />
        {events.map((ev, index) => (
          <ObserverSection key={ev.id} className="calendar-event">
            <div className="calendar-event-spacer" />
            <div className="calendar-event-indicator" />
            <div className="calendar-event-content">
              <div className="calendar-event-date">
                {ev?.eventSettings?.date
                  ? dayjs(ev?.eventSettings?.date)
                      .locale(locale)
                      .format('DD MMM YYYY')
                  : intl.formatMessage({ id: 'calendar.soon' })}
              </div>
              <div className="calendar-event-title">
                {locale === 'en' && ev?.eventSettings?.eventNameEn ? ev?.eventSettings?.eventNameEn : ev?.eventSettings?.eventName}
              </div>
              {ev?.eventSettings?.platformName && (
                <>
                  {(ev?.eventSettings?.linkToEvent && (
                    <a
                      href={ev?.eventSettings?.linkToEvent}
                      target="_blank"
                      rel="noreferrer"
                      className="event-platform-link"
                    >
                      <div className="calendar-event-platform">
                        {ev?.eventSettings?.platformName}
                        <LinkIcon />
                      </div>
                    </a>
                  )) || (
                    <div className="calendar-event-platform">
                      {ev?.eventSettings?.platformName}
                    </div>
                  )}
                </>
              )}
            </div>
          </ObserverSection>
        ))}
      </div>
    </Layout>
  );
};

export default Calendar;
